import React, { useState, useEffect } from "react";
import Layout from "../components/layout";
import Seo from "../components/seo";
import HeaderAd from "../components/ads/header-ad";
import AuthService from "../services/auth.service";
import { useQueryParam , StringParam } from "use-query-params";
import { useForm } from "react-hook-form";

// markup
const ResetPassword = () => {
  const {
    register,
    handleSubmit, 
    formState: { errors },
  } = useForm();
  const [token ] = useQueryParam("token", StringParam);
  const [message, setMessage] = useState(null);
  const [tokenValid, setTokenValid] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
 

  const onSubmit = async (data) => { 
    if (data.password_1 !== data.password_2) {
      alert("Passwords do not match");
      return false;
    } else{
        const reply = await AuthService.updatePasswordToken(token, data.password_1);
        console.log(reply)
        if(reply.status === 'success'){
            setMessage(reply.message)
        }
    }
  };
  useEffect(() => { 
    const checkToken = async () => {
      const reply = await AuthService.checkPasswordToken(token); 
      setMessage(reply.message);
      if (reply.status === "success") {
        setTokenValid(true);
      }
    };
    checkToken();
  }, []);

  return (
    <Layout>
      <Seo title={`Online Hangman`} />
      <div>
        <HeaderAd />
      </div>
      <div className={"container mx-auto px-4 pb-6 py-6"}>
        <h1 className={"text-4xl font-bold mb-10"}>Reset Your Password</h1>
        <div className={"w-full p-4  bg-white"}>
          {message && message} 
          {tokenValid ===
          true /* "handleSubmit" will validate your inputs before invoking "onSubmit" */ ? (
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 flex flex-col">
                <div className="mb-4">
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="email"
                  >
                    Password
                  </label>
                  <input
                    className="shadow appearance-none border rounded w-full py-2 px-3 text-grey-darker"
                    name="email"
                    id="email"
                    type="password"
                    {...register("password_1", { required: true })}
                    placeholder="New Password"
                  />
                </div>
                <div className="mb-6">
                  <label
                    className="block text-grey-darker text-sm font-bold mb-2"
                    htmlFor="password"
                  >
                    Confirm Password
                  </label>
                  <input
                    className="shadow appearance-none border border-red rounded w-full py-2 px-3 text-grey-darker mb-3"
                    name="password"
                    password="password"
                    type="password"
                    {...register("password_2", { required: true })}
                    placeholder="Confirm new password"
                  />
                </div>
                {errorMessage && (
                  <p className={"text-red-900 mb-4"}>{errorMessage}</p>
                )}
                <div className="flex items-center justify-between">
                  <button
                    className="bg-blue-900 hover:bg-blue-dark text-white font-bold py-2 px-4 rounded"
                    type="submit"
                  >
                    Sign In
                  </button>
                </div>
              </div>
            </form>
          ) : null}
          {/* <p>About us coming soon.</p> */}
        </div>
      </div>
    </Layout>
  );
};

export default ResetPassword;
